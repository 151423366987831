import '../sentry.client.config';
import '../styles/globals.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import type { AppProps } from 'next/app';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  ApiError,
  Policy,
  AppNameProvider,
  Forbidden,
  ErrorFallback,
  Layout,
} from 'ui';
import { getVersion } from '../utils/version';
import useUser from '../auth/useUser';
import { APP_NAME } from '../appName';
import { ErrorBoundary } from 'react-error-boundary';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import theme from '../styles/theme';
import { useRouter } from 'next/router';

// We load this component dynamically to disable SSR on it because it depends on the value of the window object.
const AuthContextProvider = dynamic(
  () => {
    return import('../auth/AuthContext');
  },
  { ssr: false }
);

const errorFunction = (error: unknown) => {
  if (error instanceof ApiError) {
    console.error(error, {
      errorCode: error.errorCode,
      appName: error.appName,
      ctx: error.ctx,
      cause: error.cause,
      requestId: error.requestId,
      errorId: error.errorId,
    });
    toast.error(
      `Error: ${error.message}\nCódigo: ${error.errorCode}\n[${error.errorId}]`,
      {
        duration: 5000,
      }
    );
    return;
  }

  let realError = error;
  if (!(error instanceof Error)) {
    realError = new Error(JSON.stringify(error));
  }

  console.error(realError);
  toast.error(`Error: ${(realError as Error).message}`);
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: errorFunction,
  }),
  mutationCache: new MutationCache({
    onError: errorFunction,
  }),
});

type ExtendedAppProps = AppProps & {
  Component: AppProps['Component'] & { skipAuth?: boolean };
};

function ComponentWrapper({ Component, pageProps }: ExtendedAppProps) {
  const user = useUser();
  const router = useRouter();

  const { company } = router.query;

  const asideMenuOptions = useMemo(() => {
    return company
      ? [
          {
            title: 'Inicio',
            path: `/${company}`,
            icon: <DashboardIcon color="primary" />,
          },
          {
            title: 'Certificados',
            path: `/${company}/requests-certificate`,
            icon: <TaskOutlinedIcon color="primary" />,
          },
          {
            title: 'Facturas',
            path: `/${company}/invoices`,
            icon: <FileCopyOutlinedIcon color="primary" />,
          },
          {
            title: 'Cuentas por cobrar',
            path: `/${company}/accounts-receivable`,
            icon: <PaidOutlinedIcon color="primary" />,
          },
          {
            title: 'Pronto pago',
            path: `/${company}/prompt-payment`,
            icon: <HourglassEmptyOutlinedIcon color="primary" />,
          },
        ]
      : [];
  }, [company]);

  if (Component.skipAuth) {
    return (
      <React.StrictMode>
        <Component {...pageProps} />
      </React.StrictMode>
    );
  }

  // The AuthContextProvider (and the keycloak-js library) does not play well with StrictMode's unmounting and remounting of the components.
  // Running AuthContextProvider inside StrictMode causes an infinite redirect loop when coming back from the idP.
  return (
    <AuthContextProvider clientId="monorepo">
      <Policy
        user={user}
        roles={['login']}
        fallback={
          <Forbidden
            text={'No tienes permiso para acceder a la aplicación'}
            userLogout={user?.actions?.logout}
            email={user?.email}
          />
        }
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout
            pageTitle="Mercury"
            userName={user?.name}
            noPageTitleHeader={true}
            customMainStyle={{
              top: '60px',
              minWidth: '410px',
              padding: theme.spacing(2),
            }}
            customHeaderStyle={{
              borderBottom: '1px solid #eeeeee',
              opacity: 1,
            }}
            asideMenuOptions={asideMenuOptions}
            customButtonsAsideStyle={{ height: 72 }}
            enabledUserIcon={true}
            userLogout={user?.actions?.logout}
          >
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </Policy>
    </AuthContextProvider>
  );
}

function MyApp(props: ExtendedAppProps) {
  console.log('Version', getVersion());

  return (
    <ErrorBoundary FallbackComponent={() => <ErrorFallback />}>
      <AppNameProvider appName={APP_NAME}>
        <RecoilRoot>
          <Toaster />
          <QueryClientProvider client={queryClient}>
            <ComponentWrapper {...props} />
          </QueryClientProvider>
        </RecoilRoot>
      </AppNameProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
