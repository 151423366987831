import { TextField, Autocomplete } from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';

export interface AutocompleteSelectOptions {
  label: string;
  value: number | string;
}

interface ControlledSelectInputProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: string;
  options: AutocompleteSelectOptions[];
  controllerProps?: any;
  inputsProps?: any;
  errors: any;
  isDirty: boolean;
  disablePortal?: boolean;
  isMultiple?: boolean;
}

const ControlledSelectInput = <T extends FieldValues>(
  props: ControlledSelectInputProps<T>
) => {
  const { disablePortal = true, isMultiple = false } = props;

  return (
    <Controller
      {...props.controllerProps}
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange } }) => {
        const selectedOption = isMultiple
          ? props.options.filter((option) => value?.includes(option.value))
          : props.options.find((option) => option.value === value) || null;

        return (
          <Autocomplete
            multiple={isMultiple}
            value={selectedOption}
            {...props.inputsProps}
            disablePortal={disablePortal}
            options={props.options}
            getOptionLabel={(option: AutocompleteSelectOptions) => option.label}
            isOptionEqualToValue={(
              option: AutocompleteSelectOptions,
              value: AutocompleteSelectOptions
            ) => option.value === value.value}
            renderOption={(props, option: AutocompleteSelectOptions) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={props.label}
                helperText={
                  props.isDirty && props.errors && props.errors.message
                }
                error={props.isDirty && Boolean(props.errors)}
              />
            )}
            onChange={(e: any, data: any) => {
              if (isMultiple) {
                onChange(
                  data.map((option: AutocompleteSelectOptions) => option.value)
                );
              } else {
                onChange(data?.value || null);
              }
            }}
          />
        );
      }}
    />
  );
};

export default ControlledSelectInput;
